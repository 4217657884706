import { AnimatedTextBlock } from "@components/atoms/AnimatedTextBlock"
import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { Pill } from "@components/atoms/Pill"
import { defineBlock, EditableText, useInlineEditableValue } from "eddev/blocks"
import { twMerge } from "tailwind-merge"

export const meta: BlockMeta = {
  title: "Home Heading",
}

export default defineBlock("home/heading", (props) => {
  const [title, setTitle] = useInlineEditableValue("title", "Example heading")
  const [pill, setPill] = useInlineEditableValue("pill", "Example pill")
  const [body, setBody] = useInlineEditableValue("body", "Example body")
  const showTitle = (title && title !== "Example heading") || env.admin
  const showPill = (pill && pill !== "Example pill") || env.admin
  const showBody = (body && body !== "Example body") || env.admin

  return (
    <BlockWrapper toolbarState={!showPill ? "live-index" : "course-progress"} label="heading">
      <BlockItem className={twMerge("bg-beige", props.invertTheme ? "bg-black text-orange" : "")} innerClassName="md:pb-4">
        <div className={twMerge("flex w-full gap-5 flex-col md:pt-1 md:justify-between", showPill ? "md:flex-row-reverse" : "")}>
          {showPill && (
            <div className="shrink-0">
              <Pill className="bg-beige-dark shrink-0">
                <EditableText
                  as="span"
                  className="shrink-0"
                  store="pill"
                  inlineToolbar={false}
                  defaultValue="Example pill"
                  placeholder="Example pill"
                />
              </Pill>
            </div>
          )}
          {showTitle && (
            <AnimatedTextBlock textKey="title" textClassName={twMerge("type-title-l w-full", showPill ? "md:w-3/4" : "")} triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder="Example heading" />
          )}
        </div>
        {showBody && (
          <AnimatedTextBlock textKey="body" className={twMerge("block w-full md:w-1/2", showTitle ? "mt-6" : "")} textClassName="type-body-l" triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder="Example body" />
        )}
      </BlockItem>
    </BlockWrapper>
  )
})